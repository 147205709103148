import React, { createContext, useContext } from "react";

import { StrainV2 } from "custom-types/StrainV2";

type StrainPageContextProps = {
  strain: StrainV2 | Record<string, never>;
  eventPage?: string;
};

const StrainPageContext = createContext<StrainPageContextProps>({
  eventPage: "",
  strain: {},
});

StrainPageContext.displayName = "StrainPageContext";

type Props = {
  children: React.ReactNode;
  strain: StrainV2;
  eventPage?: string;
};

export const StrainPageProvider = ({ children, strain, eventPage }: Props) => (
  <StrainPageContext.Provider
    value={{
      eventPage,
      strain,
    }}
  >
    {children}
  </StrainPageContext.Provider>
);

export const useStrainPageContext = () => useContext(StrainPageContext);

export default StrainPageContext;
