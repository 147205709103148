import classNames from "classnames";
import isEmpty from "lodash/isEmpty";

import { useStrainPageContext } from "context/StrainPageContext";
import { cdfNormal } from "utils/cdfNormal";
import { sortTopCannabs } from "utils/sortTopCannabs";

import Scale from "./Scale";

type Props = {
  className?: string;
};

const StrainAttributes = ({ className }: Props) => {
  const { strain } = useStrainPageContext();

  if (isEmpty(strain)) {
    return <></>;
  }

  const sortedTopCannabs = sortTopCannabs(strain.cannabinoids);
  const thcPercentage = sortedTopCannabs.find(
    (obj) => obj.displayName === "THC",
  )?.percentile50;

  const normalizedTHCPercentage =
    typeof thcPercentage === "number"
      ? thcPercentage > 0
        ? (thcPercentage * 100) / 30
        : 1
      : null;

  const normalizedEnergizeScore =
    strain.energizeScore !== null &&
    cdfNormal(strain.energizeScore, 0, 0.8) * 100;

  return (
    <div className={classNames(className, "pt-md")}>
      {normalizedEnergizeScore && (
        <Scale
          className="mb-md"
          percentage={normalizedEnergizeScore}
          leftLabel="calming"
          rightLabel="energizing"
          screenReaderText={`${strain.name} effects are mostly ${
            normalizedEnergizeScore < 50 ? "calming." : "energizing."
          }`}
        />
      )}
      {normalizedTHCPercentage && (
        <Scale
          className="mb-md"
          percentage={normalizedTHCPercentage}
          leftLabel="low THC"
          rightLabel="high THC"
          screenReaderText={`${strain.name} potency is ${
            normalizedTHCPercentage < 50
              ? "lower THC than average."
              : "higher THC than average."
          }`}
        />
      )}
    </div>
  );
};

export default StrainAttributes;
